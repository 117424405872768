import { createAlova, invalidateCache } from 'alova';
import { axiosRequestAdapter } from '@alova/adapter-axios';
import ReactHook from 'alova/react';

import { alovaStorageAdapter } from '@common/apis/alovaStorageAdapter';
import { handleErrorLog } from '@common/utils/handleErrorLogs';
import { handleBeforeRequest } from '@common/utils/handleBeforeRequest';
import { handleSignOut } from '@common/utils/handleToken';

export const alovaScoreInstance = createAlova({
  statesHook: ReactHook,
  requestAdapter: axiosRequestAdapter(),
  baseURL: import.meta.env.VITE_SCORE_API,
  storageAdapter: alovaStorageAdapter,
  cacheLogger: import.meta.env.DEV,
  responded: {
    onSuccess: (response: any) => {
      return response.data;
    },
    onError: async (error) => {
      handleErrorLog(error);

      if (error?.response?.status === 401) {
        await handleSignOut();
      }
    },
    onComplete: invalidateCache,
  },
  async beforeRequest(method) {
    await handleBeforeRequest(method);
  },
});
