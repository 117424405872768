import classNames from 'classnames';
import './styles.scss';
export interface IContainerProps {
  children: React.ReactNode;
  expandY?: boolean;
  customClass?: string;
  centralize?: boolean;
}
export const Container: React.FC<IContainerProps> = ({
  expandY,
  customClass,
  centralize,
  children,
}) => {
  const safeCustomClass = customClass || '';
  const containerClass = classNames(['container', safeCustomClass], {
    expandY: expandY,
    centralize,
  });

  return <div className={containerClass}>{children}</div>;
};
