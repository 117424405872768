import untilLoad from '@libs/utils/helpers/untilLoad';

interface AddToHotjarProps {
  customerIdHash?: string;
  anonymousId?: string;
  sessionExperiments?: string;
  sessionVariants?: string;
  partnerIdentifier?: string;
}

export default function useHotjar() {
  function updateLoggedUser(customerIdHash: string) {
    if (!(window as any)?.hj) return;
    const hjUserAttributes = (window as any)?.hj?.userAttributes;
    const hjAttributes = hjUserAttributes?.attributes;

    const hjUserId = hjUserAttributes?.id;
    const hjCustomerId = hjAttributes?.customer_id_hash;

    if (!!customerIdHash && !hjCustomerId && !!hjUserId) {
      hjUserAttributes.set(hjUserId, {
        customer_id_hash: customerIdHash,
      });
    }

    if (!customerIdHash && hjCustomerId && !!hjUserId) {
      hjUserAttributes.set(hjUserId, {
        customer_id_hash: '',
      });
    }
  }

  /**
   * Adds identification information and session variables to Hotjar for behavior analysis.
   * @param {string} [props.customerIdHash] - Customer identification hash.
   * @param {string} [props.anonymousId] - Anonymous user ID.
   * @param {string} [props.sessionExperiments] - Session experiments.
   * @param {string} [props.sessionVariants] - Session variants.
   * @param {string} [props.partnerIdentifier] - Partner identifier.
   */
  async function add({
    customerIdHash,
    anonymousId,
    sessionExperiments,
    sessionVariants,
    partnerIdentifier,
  }: AddToHotjarProps) {
    const hj = await untilLoad(() => (window as any).hj);

    if (!hj) return console.warn('[hj - addToHotjar] is not defined.');

    if (hj) {
      const identifyData = {
        ...(!!customerIdHash && { customer_id_hash: customerIdHash }),
        ...(!!anonymousId && { anonymous_id: anonymousId }),
        ...(!!sessionExperiments && {
          session_experiments: sessionExperiments,
        }),
        ...(!!sessionVariants && { session_variants: sessionVariants }),
        ...(!!partnerIdentifier && { partner_identifier: partnerIdentifier }),
      };

      const userId =
        anonymousId ||
        `${Math.random() * 9.99}|identify|${Math.random() * 999}|default`;
      hj('identify', userId, identifyData);
    }
  }

  /* Tracks an event on Hotjar for behavior analysis.
   * @param {string} eventName - Name of the event to track.
   */
  async function trackEventHotjar(eventName: string) {
    const hj = await untilLoad(() => (window as any).hj);

    if (!hj) return console.warn('[hj - trackEventHotjar] is not defined.');

    if (hj) {
      hj('event', eventName);
    }
  }

  return {
    updateLoggedUser,
    add,
    trackEventHotjar
  };
}
