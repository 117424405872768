import { useState } from 'react';
import { createPortal } from 'react-dom';
import { IconChevronRight, Text } from '@consumidor-positivo/aurora';
import { type MatchMakerDecision } from '@hub/types/matchmaker';

import { useTracking } from '@common/hooks/useTracking';
import { CreditCardModal } from '@hub/pages/MatchMaker/components/CreditCardModal';

import helpCircleIcon from '@hub/assets/help-circle.svg';
import './styles.scss';

type DescriptionProps = {
  offer: MatchMakerDecision;
};

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 0,
  }).format(value);
};

const userHasFullSpend = (
  monthlyExpenses: number,
  minimumSpendFull: number
) => {
  return minimumSpendFull !== 0 && monthlyExpenses >= minimumSpendFull;
};

const userHasHalfSpend = (monthlyExpenses: number, minimumSpend50: number) => {
  return minimumSpend50 !== 0 && monthlyExpenses >= minimumSpend50;
};

const getAnnuityText = (offer: MatchMakerDecision) => {
  const {
    annuityMonthlyFee,
    monthlyExpenses,
    minimumSpend50,
    minimumSpendFull,
  } = offer;

  if (annuityMonthlyFee === 0) {
    return 'Anuidade grátis';
  }

  if (userHasFullSpend(monthlyExpenses, minimumSpendFull)) {
    return `Anuidade grátis gastando ${formatCurrency(minimumSpendFull)}`;
  }

  if (userHasHalfSpend(monthlyExpenses, minimumSpend50)) {
    return `Anuidade de ${formatCurrency(
      annuityMonthlyFee / 2
    )}/mês gastando ${formatCurrency(minimumSpend50)}`;
  }

  return `Anuidade de ${formatCurrency(annuityMonthlyFee)}/mês`;
};

const isAnnuityFree = (offer: MatchMakerDecision) => {
  const { annuityMonthlyFee, monthlyExpenses, minimumSpendFull } = offer;

  return (
    annuityMonthlyFee === 0 ||
    (minimumSpendFull !== 0 && monthlyExpenses >= minimumSpendFull)
  );
};

export const CreditDescription: React.FC<DescriptionProps> = ({ offer }) => {
  const [isAnnuityModalOpen, setIsAnnuityModalOpen] = useState(false);
  const [isTravelBenefitsModalOpen, setIsTravelBenefitsModalOpen] =
    useState(false);

  const { elementClickedEvent } = useTracking();

  const annuityInfo = offer?.annuityDetails.find(
    (detail) => detail.component === 'info_feature'
  );

  const travelBenefits = offer?.loyalty.find(
    (detail) => detail.component === 'travel_benefits'
  );

  const programPoints = offer?.loyalty.find(
    (detail) => detail.component === 'points_program'
  );

  const handleOpenAnnuityModal = () => {
    elementClickedEvent({
      elementType: 'button',
      location: '/comparador/sucesso',
      name: 'annuity-info',
      text: offer.slug,
    });

    setIsAnnuityModalOpen(true);
  };

  const handleOpenTravelBenefitsModal = () => {
    elementClickedEvent({
      elementType: 'button',
      location: '/comparador/sucesso',
      name: 'travel-benefits-info',
      text: offer.slug,
    });

    setIsTravelBenefitsModalOpen(true);
  };

  return (
    <>
      <div className="credit-description">
        {offer.category === 'cashback' &&
          offer.featureDescriptions.slice(0, 2).map((feature, index) => (
            <div className="credit-description__details" key={index}>
              <div className="credit-description__title">
                <Text as="p" variant="body-medium">
                  {feature.title}
                </Text>
              </div>
              <div className="credit-description__description">
                <Text as="p" variant="body-medium">
                  <strong>{feature.description}</strong>
                </Text>
              </div>
            </div>
          ))}

        {offer.category === 'miles' && (
          <>
            <div className="credit-description__details">
              <div className="credit-description__title">
                <Text as="p" variant="body-medium">
                  Vantagens para viajar
                </Text>
              </div>
              <div className="credit-description__description">
                {travelBenefits ? (
                  <div onClick={handleOpenTravelBenefitsModal}>
                    <Text
                      as="p"
                      variant="body-medium"
                      className="credit-description__description--travel-benefits"
                    >
                      <strong>{travelBenefits?.text_button}</strong>
                    </Text>
                    <IconChevronRight size="default" />
                  </div>
                ) : (
                  <Text as="p" variant="body-medium">
                    <strong>Não tem</strong>
                  </Text>
                )}
              </div>
            </div>
            <div className="credit-description__details">
              <div className="credit-description__title">
                <Text as="p" variant="body-medium">
                  Programa de pontos
                </Text>
              </div>
              <div className="credit-description__description">
                {programPoints && (
                  <Text as="p" variant="body-medium">
                    <strong>{programPoints?.name}</strong>
                  </Text>
                )}
              </div>
            </div>
          </>
        )}

        <div className="credit-description__annuity">
          <hr className="credit-description__annuity__divider" />
          <Text
            as="p"
            variant="body-small"
            className={`credit-description__annuity__text${
              isAnnuityFree(offer)
                ? ' credit-description__annuity__text--green'
                : ''
            }`}
          >
            {getAnnuityText(offer)}
          </Text>

          {Boolean(annuityInfo) && (
            <img
              src={helpCircleIcon}
              alt="Icone de circulo com uma interrogação no meio"
              onClick={handleOpenAnnuityModal}
            />
          )}
        </div>
      </div>

      {annuityInfo &&
        createPortal(
          <CreditCardModal
            isOpen={isAnnuityModalOpen}
            onClose={() => setIsAnnuityModalOpen(false)}
            iconURL={
              (annuityInfo?.icon?.length && annuityInfo?.icon[0].filename) || ''
            }
            title={annuityInfo?.title}
            description={annuityInfo?.description}
          />,
          document.body
        )}

      {travelBenefits &&
        createPortal(
          <CreditCardModal
            isOpen={isTravelBenefitsModalOpen}
            onClose={() => setIsTravelBenefitsModalOpen(false)}
            iconSize={40}
            iconURL={
              (travelBenefits?.icon?.length &&
                travelBenefits?.icon[0].filename) ||
              ''
            }
            title={travelBenefits?.title}
            description={travelBenefits?.description}
          />,
          document.body
        )}
    </>
  );
};
