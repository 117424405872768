import { Navigate, Outlet, useSearchParams } from 'react-router-dom';

import { RouteMap } from '@common/constants/RouteMap';
import { LoaderCircular } from '@common/components/LoaderCircular';
import { useUserContext } from '@common/contexts/UserContext';
import { base64Decrypt } from '@libs/utils/helpers/base64';

import { useEffect } from 'react';
import { unmask } from 'remask';

const PublicRoute = () => {
  const { isAuthenticated, isLoading, user, signOut } = useUserContext();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const identify = searchParams.get('identify');
    if (identify && user) {
      const decodedDocument = unmask(base64Decrypt(identify));
      if (user?.document !== decodedDocument) signOut();
    }
  }, [user]);

  if (isLoading) return <LoaderCircular fullPage />;
  if (!isLoading && !isAuthenticated)
    return <Navigate to={RouteMap.Root + window.location.search} replace />;

  return <Outlet />;
};

export default PublicRoute;
