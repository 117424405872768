import { Text, IconChevronDown } from '@consumidor-positivo/aurora';

type CollapseProps = {
  title: string;
  icon?: JSX.Element;
  startOpened?: boolean;
  children: React.ReactNode;
};

import './styles.scss';
import { useCollapse } from './hook';
export const Collapse: React.FC<CollapseProps> = ({
  children,
  title,
  startOpened = false,
  icon,
}) => {
  const { handleClick, hubClass } = useCollapse(title, startOpened);

  return (
    <div className={hubClass} onClick={handleClick}>
      <div className="collapse-hub__header">
        <div className="collapse-hub__title">
          {icon}
          <Text as="h4" variant="heading-micro" weight="bold">
            {title}
          </Text>
        </div>
        <div className="collapse-hub__icon">
          <IconChevronDown />
        </div>
      </div>
      <div className="collapse-hub__content">
        <div className="collapse-hub__content__inner">
          <div className="collapse-hub__divider"></div>
          {children}
        </div>
      </div>
    </div>
  );
};
