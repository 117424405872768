import Cookies from 'js-cookie';
import { PostWebViewMap } from '@common/constants/PostWebViewMap';
import { CookieMap } from '@common/constants/CookieMap';
import { ApiSigninResponse } from '@admission/apis/auth/types';

export const isUserFromWebView = () => {
  // This function returns false if the user is in a new version of the app (v6+)
  if (window.isNativeApp) return false;

  const hasInstallationToken = !!Cookies.get(CookieMap.installationToken);
  return hasInstallationToken;
};

export const getAccessData = () =>
  new Promise<ApiSigninResponse | undefined>((resolve, reject) => {
    try {
      setTimeout(() => {
        const accessTokenFromApp = Cookies.get(CookieMap.accessToken);
        if (accessTokenFromApp) resolve(JSON.parse(accessTokenFromApp));
        else resolve(undefined);
      }, 100);
    } catch (error) {
      reject(error);
    }
  });

export const redirectInApp = (url: string) =>
  window.ReactNativeWebView.postMessage(`${PostWebViewMap.redirect}:${url}`);

export const sendTokenToNativeApp = (data: string) =>
  window.ReactNativeWebView.postMessage(`${PostWebViewMap.SignIn}:${data}`);

export const cleanTokenNativeApp = () => {
  window.ReactNativeWebView.postMessage(`${PostWebViewMap.signOut}`);
};
