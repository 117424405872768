import useConfirmDialog from '@common/components/ConfirmDialog/hook';
import { StorageMap } from '@common/constants/StorageMap';
import { useTracking } from '@common/hooks/useTracking';
import { getFromStorage, setOnStorage } from '@common/utils/handleStorage';
import { removeScheduleNotification } from '@common/utils/scheduleNotification';
import { MatchMakerDecision } from '@hub/types/matchmaker';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useMyFavorites = () => {
  const [offers, setOffers] = useState<MatchMakerDecision[]>([]);
  const { ConfirmDialog, confirm } = useConfirmDialog();
  const { elementClickedEvent, elementViewedEvent } = useTracking();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getFavoritesFromStorage();
    getEventFromUrl();
  }, []);

  function getFavoritesFromStorage() {
    const favorites = getFromStorage<[]>(StorageMap.FavoriteOffers);
    setOffers(favorites || []);
  }

  function getEventFromUrl() {
    const queryParams = new URLSearchParams(location.search);
    const event = queryParams.get('event') || false;
    queryParams.delete('event');
    navigate({ search: queryParams.toString() });

    elementViewedEvent({
      elementType: 'content',
      name: 'favoritar',
      location: window.location.pathname,
      text: event ? 'push notification' : 'navigate',
    });
  }

  async function handleRemove(offer: MatchMakerDecision) {
    const result = await confirm({
      title: 'Remover favorito',
      message: 'Tem certeza que deseja remover este cartão?',
    });

    if (result) {
      elementClickedEvent({
        elementType: 'button',
        name: 'favoritar',
        location: window.location.pathname,
        text: `remover - ${offer.slug}`,
      });

      const updatedFavorites = offers.filter((fav) => fav.slug !== offer.slug);
      setOffers(updatedFavorites);
      setOnStorage('local', StorageMap.FavoriteOffers, updatedFavorites);
      if (updatedFavorites.length < 1) {
        removeScheduleNotification('favorite');
      }
    }
  }

  return {
    offers,
    handleRemove,
    ConfirmDialog,
  };
};
