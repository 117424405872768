type fillUrlParametersProps = {
    marketplace: 'cp' | 'ac';
    startSourceUrl: string;
    getDatasource: any;
  };

export const getSiteIdParam = async ({
    marketplace,
    startSourceUrl,
    getDatasource,
  }: fillUrlParametersProps) => {

    if(!marketplace || !startSourceUrl || !getDatasource) return;

    const urlParams = new URLSearchParams(new URL(startSourceUrl).search);

    const utmSource = urlParams.get('utm_source');
    const utmMedium = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign') || '0';
    const utmTest = urlParams.toString().match(/test-(\w+)/);
    const hasTest = utmTest ? `_${utmTest[0]}` : '';

    const datasource = await getDatasource('offer-url-parameters');

    const mkt = datasource.find(
      (item: any) => item.name === `mkt_${marketplace}`
    )?.value;
    const source =
      datasource.find((item: any) => item.name === `so_${utmSource}`)?.value ||
      '0';
    const medium =
      datasource.find((item: any) => item.name === `me_${utmMedium}`)?.value ||
      '0';

    const siteIdParam = `mkt-${mkt}_so-${source}_me-${medium}_ca-${utmCampaign}${hasTest}`;

    return siteIdParam;
  };