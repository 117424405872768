import { unmask } from 'remask';
import {
  ApiSigninRequest,
  ApiSignupRequest,
  ApiCheckDocumentRequest,
  ApiForgotPasswordRequest,
  ApiSendEmailRequest,
  ApiForgotPasswordFindRequest,
  ApiForgotPasswordResetRequest,
  ApiChangePasswordRequest,
  ApiForgotPasswordValidateRequestLegacy,
  ApiGenerateOTPRequest,
  ApiCheckOTPRequest,
} from './types';

export const transformSigninPayload = (data: ApiSigninRequest) => {
	if(data.facebook_access_token) return data;

	return {
		document: unmask(data.document),
		password: data.password,
		captcha_response: data?.captchaResponse || '',
		captcha_action: data?.captchaAction || '',
	}
};

export const transformSignupPayload = (data: ApiSignupRequest) => ({
  birthdate: data.birthdate,
  document: unmask(data.document),
  email: data.email,
  full_name: data.full_name,
  gender: data.gender,
  password: data.password,
  phone: unmask(data.phone),
  social_name: data.full_name,
  termsOfUse: data.termsOfUse,
  termsOfUseVersion: data.termsOfUseVersion,
  kba: data.kba,
  captcha_response: data?.captchaResponse || '',
  captcha_action: data?.captchaAction || '',
  notification_type: data.notificationType,
});

export const transformCheckDocumentPayload = (
  data: ApiCheckDocumentRequest
) => ({
  document: unmask(data.document),
  captcha_response: data?.captchaResponse || '',
  captcha_action: data?.captchaAction || '',
});

export const transformForgotPasswordPayload = (
  data: ApiForgotPasswordRequest
) => ({
  document: unmask(data.document),
});

export const transformForgotPasswordValidatePayload = (
  data: ApiSendEmailRequest
) => ({
  document: unmask(data.document),
  email: data.email,
});
export const transformForgotPasswordValidatePayloadLegacy = (
  data: ApiForgotPasswordValidateRequestLegacy
) => ({
  document: unmask(data.document),
  token: data.token,
});

export const transformForgotPasswordFindPayload = (
  data: ApiForgotPasswordFindRequest
) => ({
  token: data?.token,
});

export const transformForgotPasswordResetPayload = (
  data: ApiForgotPasswordResetRequest
) => ({
  password: data?.password,
  token: data?.token,
});

export const transformChangePasswordPayload = (
  data: ApiChangePasswordRequest
) => ({
  current_password: data?.currentPassword,
  new_password: data?.newPassword,
});

export const transformGenerateOTPPayload = (
  data: ApiGenerateOTPRequest
) => ({
  document: unmask(data.document)
});

export const transformCheckOTPPayload = (
  data: ApiCheckOTPRequest
) => ({
  document: unmask(data.document),
  code: data.code,
});
