import { getApp } from './getApplication';

type AutofillData = {
  url: string;
  productSlug: string;
  user: FireboltUserInfo;
};

export type FireboltUserInfo = {
  cpf?: string;
  fullName?: string;
  email?: string;
  phone?: string;
};

export const addAutofillParam = ({ url, productSlug, user }: AutofillData) => {
  const application = getApp();

  const payload = {
    productSlug: productSlug,
    application,
    cpf: user?.cpf,
    full_name: user?.fullName,
    email: user?.email,
    main_phone: user?.phone,
  };

  const encodedAutofill = encodeURIComponent(JSON.stringify(payload));
  const encryptedAutofill = window.btoa(encodedAutofill);
  const concatChar = url.includes('?') ? '&' : '?';
  return `${url}${concatChar}autofill=${encryptedAutofill}`;
};
