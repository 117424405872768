import { createAlova } from 'alova';
import { axiosRequestAdapter } from '@alova/adapter-axios';
import ReactHook from 'alova/react';

import { handleErrorLog } from '@common/utils/handleErrorLogs';
import { alovaStorageAdapter } from '@common/apis/alovaStorageAdapter';

export const alovaFireboltInstance = createAlova({
  statesHook: ReactHook,
  requestAdapter: axiosRequestAdapter(),
  baseURL: import.meta.env.VITE_FIREBOLT_API_URL,
  storageAdapter: alovaStorageAdapter,
  cacheLogger: import.meta.env.DEV,
  responded: {
    onSuccess: async (response) => response?.data,
    onError: (error) => {
      handleErrorLog(error);
    },
  },
});
