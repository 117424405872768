import { TypesConfigOptional } from 'react-spinetic/types';

export const carouselConfig: TypesConfigOptional = {
  layout: 'ctrls-in-line',
  groupItemsScroll: 1,
  showItems: 3,
  groupScroll: true,
  autoWidth: true,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        layout: 'ctrls-in-line-2',
        showItems: 1,
        // maxDots: true,
        autoWidth: true,
      },
    },
  ],
};
