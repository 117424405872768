import { RouteMap } from '@common/constants/RouteMap';
import './styles.scss';
import { Button, Text } from '@consumidor-positivo/aurora';
import CreditCardIcon from '@hub/assets/credit-card-toon.svg';
import { useNavigate } from 'react-router-dom';
import { useTracking } from '@common/hooks/useTracking';

export const FavoriteEmpty = () => {
  const navigate = useNavigate();
  const { elementClickedEvent } = useTracking();

  const handleClick = () => {
    elementClickedEvent({
      elementType: 'button',
      name: 'favoritar',
      location: window.location.pathname,
      text: `Encontrar meu cartão ideal`,
    });
    navigate(RouteMap.MatchMakerResult);
  };
  return (
    <div className="favorite-empty">
      <div className="favorite-empty__img">
        <img src={CreditCardIcon} alt="desenho de um cartão de crédito" />
      </div>
      <div className="favorite-empty__texts">
        <Text as="h2" variant="heading-micro" weight="bold">
          Você ainda não tem cartões favoritos
        </Text>
        <Text as="p" variant="body-small">
          Encontre seu cartão ideal, confira as melhores opções de{' '}
          <strong>cashback e milhas!</strong>
        </Text>
      </div>
      <div className="favorite-empty__button">
        <Button expand="x" onClick={handleClick}>
          Encontrar meu cartão ideal
        </Button>
      </div>
    </div>
  );
};
