import classNames from 'classnames';
import './styles.scss';

export interface ILoaderCircularProps {
  fullPage?: boolean;
}
export const LoaderCircular: React.FC<ILoaderCircularProps> = ({
  fullPage,
}) => {
  const loaderClass = classNames('loader-circular', {
    'loader-circular--full-page': fullPage,
  });
  return (
    <div className={loaderClass}>
      <span className="loader-circular__loader"></span>
    </div>
  );
};
