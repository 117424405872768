import { useState } from 'react';
import classNames from 'classnames';
import { useTracking } from '@common/hooks/useTracking';

export const useCollapse = (title: string, startOpened: boolean) => {
  const { elementClickedEvent } = useTracking();
  const [isOpen, setIsOpen] = useState(startOpened);
  const hubClass = classNames('collapse-hub', {
    'collapse-hub--open': isOpen,
  });

  function handleClick() {
    setIsOpen(!isOpen);

    if (!isOpen) {
      elementClickedEvent({
        elementType: 'collapse',
        location: location.pathname,
        text: title,
      });
    }
  }
  return { hubClass, handleClick };
};
