import {
  IconGift,
  IconPercent,
  IconNegotiation,
  IconCreditCard,
  IconCalendar,
} from '@consumidor-positivo/aurora';

export const icons = {
  cpPontuacao: <IconGift color="dark" />,
  cpDesconto: <IconPercent color="dark" />,
  cpParceiros: <IconNegotiation color="dark" />,
  cpContactless: <IconCreditCard color="dark" />,
  cpAnuidade: <IconCalendar color="dark" />,
};
