import { render } from 'storyblok-rich-text-react-renderer';
import { Button } from '@consumidor-positivo/aurora';

import { Modal } from '@common/components/Modal';

import './styles.scss';

interface CreditCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  iconURL?: string;
  iconSize?: number;
  title?: Record<string, unknown>;
  description?: Record<string, unknown>;
}

export const CreditCardModal = ({
  isOpen,
  onClose,
  iconSize = 24,
  iconURL,
  title,
  description,
}: CreditCardModalProps) => {
  return (
    <Modal showModal={isOpen} onClose={onClose} desktop="side" mobile="bottom">
      <div className="credit-info__modal__content">
        {!!iconURL && (
          <img
            className="credit-info__modal__icon"
            style={{ width: iconSize, height: iconSize }}
            src={iconURL}
          />
        )}
        <div className="credit-info__modal__title">{render(title || '')}</div>
        <div className="credit-info__modal__description">
          {render(description || '')}
        </div>

        <Button
          className="credit-info__modal__button"
          type="outlined"
          onClick={onClose}
        >
          Voltar
        </Button>
      </div>
    </Modal>
  );
};
