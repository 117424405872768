import { Method } from 'alova';
import { ReactState } from '@alova/scene-react';
import { AlovaAxiosRequestConfig } from '@alova/adapter-axios';
import { AxiosResponseHeaders } from 'axios';
import { getAnonymousID, getSessionID } from 'redline-client-sdk';
import {
  getToken,
  handleRefreshToken,
  handleSignOut,
  isValidToken,
  shouldRefreshToken,
} from './handleToken';

export async function handleBeforeRequest(
  method: Method<
    ReactState<any>,
    unknown,
    any,
    any,
    AlovaAxiosRequestConfig,
    any,
    AxiosResponseHeaders
  >
) {
  method.config.headers['Content-Type'] = 'application/json;charset=UTF-8';
  method.config.headers['Access-Control-Allow-Origin'] = '*';
  method.config.headers['x-anonymous-id'] = getAnonymousID();
  method.config.headers['x-session-id'] = getSessionID();

  const isPublicResquest = method.url.includes('public');
  const isRefreshTokenRequest = method.url.includes('auth/refresh-token');
  const isEngageSignin = method.url.includes('engage/signin');

  if (!isEngageSignin && (!isPublicResquest || isRefreshTokenRequest)) {
    let token = await getToken();

    if (!token || !isValidToken(token)) {
      await handleSignOut();
      method.abort();
      return;
    }

    if (isRefreshTokenRequest) {
      method.config.headers['Refresh-Token'] = token?.refresh_token;
      method.config.headers['Authorization'] = 'Bearer ' + token?.access_token;
    }

    if (!isPublicResquest) {
      if (shouldRefreshToken(token)) {
        token = await handleRefreshToken();
      }

      method.config.headers.token = token?.access_token;
      method.config.headers['X-Auth-Token'] = token?.access_token;
      method.config.headers['Authorization'] = 'Bearer ' + token?.access_token;
    }
  }
}
