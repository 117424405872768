import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { isProd } from '../helpers/isProd';

export default function useDatadogRum({
  applicationId,
  clientToken,
  site = 'datadoghq.com',
  service = '',
  env = 'production',
  version = '0.0.1',
  sessionSampleRate = 0,
  sessionReplaySampleRate = 0,
  trackUserInteractions = true,
  trackResources = true,
  trackLongTasks = true,
  defaultPrivacyLevel = 'mask-user-input',
}: Record<string, any>) {
  function initDatadog() {
    datadogRum.init({
      applicationId,
      clientToken,
      site,
      service,
      env,
      version,
      sessionSampleRate,
      sessionReplaySampleRate,
      trackUserInteractions,
      trackResources,
      trackLongTasks,
      defaultPrivacyLevel,
    });

    window.onerror = function (message, source, lineno, colno, error) {
      datadogRum.addError(error, {
        message,
        source,
        lineno,
        colno,
        ...(error && { stack: error.stack }),
      });
    };
  }

  useEffect(() => {
    if (isProd()) {
      initDatadog();
    }
  }, []);

  return {};
}
