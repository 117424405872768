import { useRequest } from 'alova';
import { alovaFireboltInstance } from './alovaFireboltInstance';
import { IFireboltApiSoftResponse } from '@tally/types';
import { transformFireboltSoftProposalPayload } from './transformPayload';

const headers = {
  'Content-Type': 'application/json;',
};
export const useApiFirebolt = () => {
  const sendSoftProposal = useRequest(
    (partnerSlug, payload) =>
      alovaFireboltInstance.Post<IFireboltApiSoftResponse<any>>(
        `/proposal/soft/${partnerSlug ?? 'default'}`,
        {
          ...transformFireboltSoftProposalPayload(payload),
        },
        {
          headers,
        }
      ),
    {
      immediate: false,
    }
  );

  return { sendSoftProposal };
};
