import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useGlobalEvents = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!window.isNativeApp) return;

    const handleNavigateTo = (event: Event) => {
      const { detail } = event as CustomEvent;

      const url = new URL(detail.url);
      navigate(`${url.pathname}${url.search}`, { replace: !!detail.replace });
    };

    window.addEventListener('rn-navigateTo', handleNavigateTo);

    return () => {
      window.removeEventListener('rn-navigateTo', handleNavigateTo);
    };
  }, [navigate]);

  return {};
};
