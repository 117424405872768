import { Accordion } from 'iq-blueberry';
import { storyblokEditable } from '@storyblok/react';
import { render } from 'storyblok-rich-text-react-renderer';
import './styles.scss';

export default function InfoAccordion({ info }: any) {

  if (!info) return null;
  return (
    <div  {...storyblokEditable(info[0])}>
      <Accordion onlyOneItemOpen>
        {info.map((blok: any, index: number) => {
          return (
            <Accordion.Item titleChild={blok?.title} key={index}>
              <div className="info-accordion__text">
                {blok?.body.map((childBlok: any) => (
                  <div {...storyblokEditable(childBlok)} key={childBlok._uid}>
                    <div className="info-accordion__paragraph">
                      <strong>{childBlok.title}</strong>
                      <div className='indo_accordion__desc'>{render(childBlok.desc)}</div>
                    </div>
                  </div>
                ))}
              </div>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
}
