import { useCallback, useState } from 'react';
import { ConfirmDialog } from '.';

interface useConfirmProps {
  title: string;
  message: string;
  buttonFalse?: string;
  buttonTrue?: string;
}

const useConfirmDialog = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [resolvePromise, setResolvePromise] = useState<
    ((value: boolean) => void) | null
  >(null);
  const [dialogProps, setDialogProps] = useState<useConfirmProps>();

  const confirm = useCallback(
    ({ title, message, buttonFalse, buttonTrue }: useConfirmProps) => {
      setDialogProps({ title, message, buttonFalse, buttonTrue });
      setIsVisible(true);

      return new Promise<boolean>(async (resolve) => {
        await setResolvePromise(() => resolve);
      });
    },
    []
  );

  const result = useCallback(
    (value: boolean) => {
      setIsVisible(false);
      if (resolvePromise) {
        resolvePromise(value);
      }
    },
    [resolvePromise]
  );

  const confirmDialogProps = {
    title: dialogProps?.title,
    message: dialogProps?.message,
    buttonFalse: dialogProps?.buttonFalse,
    buttonTrue: dialogProps?.buttonTrue,
    showConfirmDialog: isVisible,
    onConfirm: () => result(true),
    onCancel: () => result(false),
  };

  return {
    confirm,
    ConfirmDialog: () => <ConfirmDialog {...confirmDialogProps} />,
  };
};

export default useConfirmDialog;
