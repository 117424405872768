import { maskCpf } from '@libs/utils/masks/cpf';
import { maskCellphone } from '@libs/utils/masks/cellphone';
import { ApiFireboltSoftPayloadProps } from './types';

export const transformFireboltSoftProposalPayload = (
  data: ApiFireboltSoftPayloadProps
) => ({
  item: {
    cpf: maskCpf(data.item.cpf),
    full_name: data.item.full_name,
    email: data.item.email,
    main_phone: maskCellphone(data.item.main_phone),
    date_of_birth: data.item.date_of_birth,
    choosen_card: data.item.choosen_card,
    partner: 'newco',
    application: 'newco',
  },
  metadata: {
    ...data.metadata,
  },
});
