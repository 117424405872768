import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Router from '@common/routes/Router';
import { titleMap } from '@common/constants/RouteMap';
import { isProd } from '@libs/utils/helpers/isProd';
import {
  addCookie,
  getCookie,
  getCookiesStartsWith,
} from '@libs/utils/helpers/getSafeCookies';
import { setOnStorage } from '@common/utils/handleStorage';
import { StorageMap } from '@common/constants/StorageMap';
import useHotjar from '@libs/utils/hooks/useHotjar';
import { getAnonymousID } from 'redline-client-sdk';
import { useRedline } from '@libs/redline';
import { isUserFromWebView } from '@common/utils/webviewComunication';
import untilLoad from '@libs/utils/helpers/untilLoad';
import 'iq-blueberry/dist/styles.css';
import 'react-spinetic/styles.css';

function App() {
  const { track } = useRedline();
  const anonymousId = getAnonymousID();
  const hotjar = useHotjar();
  const [sentForcedSessionStarted, setSendForcedSessionStarted] = useState(false);

  function sendEventExperimentsDogx() {
    const dxCookies = getCookiesStartsWith('dx-');
    const { 'dx-bg': removedProp, ...dxCookiesOnly } = dxCookies;
    const hasTest = Object.keys(dxCookiesOnly).length;

    if (hasTest) {
      const experiments: string[] = [];
      const experimentsResults: string[] = [];

      const dxExperiments = Object.keys(dxCookiesOnly)
        .map((name) => {
          const dxValues = dxCookiesOnly[name]?.split(':');
          if (!dxValues) return null;

          const [variant, version, percentage] = dxValues;

          experiments.push(name);
          experimentsResults.push(variant);

          return {
            name,
            variant,
            version: Number(version),
            percentage: Number(percentage),
          };
        })
        .filter((experiment) => experiment !== null);

      track.dogx.experimentsLoaded({
        experiments: dxExperiments,
      });

      hotjar.add({
        anonymousId: anonymousId,

        sessionExperiments: experiments.join('|').slice(0, 199),
        sessionVariants: experimentsResults.join('|').slice(0, 199),
      });
    } else {
      hotjar.add({ anonymousId: anonymousId });

      track.dogx.experimentsLoaded({
        experiments: [{ name: 'dx-not-found', variant: 'not-found', percent: 100, version: 1 }],
      });
    }
  }

  useEffect(() => {
    forceSessionStarted();
  }, []);

  async function forceSessionStarted() {
    await untilLoad(() => window?.cpRedline?.ready, 7000);
    if (!sentForcedSessionStarted) {
      window?.cpRedline?.triggerSessionStarted();
      setSendForcedSessionStarted(true);
    }
  }

  let urlParams = window.location.search;

  if (urlParams) {
    urlParams = urlParams
      .slice(1)
      .split('&')
      .filter((param) => !param.startsWith('identify='))
      .join('&');

    setOnStorage('session', StorageMap.UrlParams, urlParams);
  }

  const location = useLocation();

  // Dynamically injects the Denakop script, if the user is not on iOS app version < 6.0.0
  // (Fixes the Denakop video player opening in fullscreen on iOS)
  useEffect(() => {
    const isIOS = isUserFromWebView();
    if (isIOS) return;

    const denakopScript = document.createElement('script');
    denakopScript.type = 'text/javascript';
    denakopScript.src =
      'https://tags.denakop.com/10881/consumidorpositivo.com.br.js';
    document.getElementsByTagName('head')[0].appendChild(denakopScript);
  }, []);

  useEffect(() => {
    const companyName = 'Consumidor Positivo';
    document.title = titleMap[location.pathname]
      ? titleMap[location.pathname] + ' - ' + companyName
      : companyName;
  }, [location]);

  useEffect(() => {
    const startSourceUrl = getCookie('start_source_url');
    if (!startSourceUrl) {
      addCookie({
        key: 'start_source_url',
        value: window.location.href,
      });
    }

    sendEventExperimentsDogx();
  }, []);

  useEffect(() => {
    if (isProd()) {
      window.__APPVERSION__ = import.meta.env.VITE_DEPLOY_VERSION;
    }
  }, []);

  return <Router />;
}

export default App;
