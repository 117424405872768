import { Component, ErrorInfo } from 'react';

type DenakopProps = {
  id: string;
  page: string;
  display?: boolean;
};

type DenakopState = {
  hasError: boolean;
};

declare const window: {
  denakop: any;
  gtag: any;
} & Window;

export default class Denakop extends Component<DenakopProps, DenakopState> {
  displayValue: boolean;

  constructor(props: DenakopProps) {
    super(props);
    this.state = { hasError: false };
    this.displayValue = props.display !== undefined ? props.display : true;
  }

  static getDerivedStateFromError(_: Error): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidMount(): void {
    if (this.displayValue) {
      try {
        if (window.denakop && window.denakop.displaySingleAd) {
          window.denakop.displaySingleAd({
            banner: {
              name: 'horizontal',
              device: 'mobile',
              className: 'horizontal_mobile',
              sizes: [
                [320, 100],
                [320, 50],
              ],
              page: this.props.page,
              selector: {
                position: 'append',
                element: `#denakop-ad-${this.props.page}-${this.props.id}`,
              },
            },
          });

          if (window?.gtag) {
            window.gtag('event', 'denakop-ad-rendered', {
              page: this.props.page,
              id: this.props.id,
            });
          } else {
            console.warn('gtag not found');
          }
        }
      } catch (error) {
        console.log('[denakop] - displaySingleAdd could not execute', error);
      }
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error('ErrorBoundary caught error:', error, info);
  }

  render() {
    if (this.state.hasError) return null;

    return <div id={`denakop-ad-${this.props.page}-${this.props.id}`} />;
  }
}
