/* eslint-disable @typescript-eslint/no-explicit-any */
import safeStorage from '@libs/utils/helpers/getSafeStorage';

export const alovaStorageAdapter = {
  set(key: string, value: any) {
    safeStorage.setItem(key, value);
  },
  get(key: string) {
    safeStorage.getItem(key);
  },
  remove(key: string) {
    safeStorage.removeItem(key);
  },
};
