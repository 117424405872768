import React, { ReactNode, createContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

import safestorage from '@libs/utils/helpers/getSafeStorage';

import useApiAuth from '@admission/apis/auth/useApiAuth';
import { ApiSigninResponse, User } from '@admission/apis/auth/types';

import { UserScore } from '@tally/types';
import { useScore } from '@tally/hooks/useScore';

import { CookieMap } from '@common/constants/CookieMap';
import { StorageMap } from '@common/constants/StorageMap';
import { handleErrorLog } from '@common/utils/handleErrorLogs';
import {
  getAccessData,
  isUserFromWebView,
  sendTokenToNativeApp,
} from '@common/utils/webviewComunication';
import {
  getToken,
  handleSignOut,
  handleRefreshToken,
  isValidToken,
  shouldRefreshToken,
} from '@common/utils/handleToken';
import { getFromStorage, setOnStorage } from '@common/utils/handleStorage';

import { removeCookie } from '@libs/utils/helpers/getSafeCookies';

interface IUserContext {
  user: User | null;
  updateUser: (user: User) => void;
  getUser: () => Promise<User | undefined>;
  signIn: (data: ApiSigninResponse, callbackFunction: () => void, authenticationType?: string) => void;
  signOut: (isLogOut?: boolean) => void;
  isAuthenticated: boolean;
	usedAuthenticationType: string;
  isLoading: boolean;
}

interface IUserProvider {
  children: ReactNode;
}

const UserContext = createContext<IUserContext>({} as IUserContext);

const UserProvider: React.FC<IUserProvider> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [usedAuthenticationType, setUsedAuthenticationType] = useState("cached-sign-in");
  const [user, setUser] = useState<User | null>(null);
  const { apiAuthCustomer, apiAuthLogout } = useApiAuth();
  const { getScore } = useScore();

  useEffect(() => {
    checkToken().then();
  }, [isAuthenticated]);

  useEffect(() => {
    window.addEventListener('userNotAuthorized', handleUserNotAuthorized);
  }, []);

  function handleUserNotAuthorized() {
    setUser(null);
    setIsAuthenticated(false);
  }

  async function handleInvalidToken() {
    setIsLoading(false);
    setIsAuthenticated(false);
    await signOut();
  }

  async function checkToken() {
    let token = await getToken();

    if (isUserFromWebView() && !window.isNativeApp) {
      const _tk = await getAccessData();
      if (_tk) token = _tk;
    }

    if (!token || !isValidToken(token)) {
      await handleInvalidToken();
      return false;
    }

    if (shouldRefreshToken(token)) {
      const updatedToken = await handleRefreshToken();

      if (!updatedToken) {
        await handleInvalidToken();
        return false;
      }

      await getUser();
    }

    setIsAuthenticated(true);
    setIsLoading(false);

    return true;
  }

  async function signIn(data: ApiSigninResponse, callbackFunction: () => void, authenticationType?: string) {
    await safestorage.setItem(StorageMap.Token, data);
    setIsAuthenticated(true);

		if(authenticationType) setUsedAuthenticationType(authenticationType);

    const user = await getUser();

    if (window.isNativeApp) {
      const pushTokens = Cookies.get('pushNotificationTokens');
      if (pushTokens && user?.email) {
        const parsedPushTokens = JSON.parse(pushTokens);
        axios.request({
          method: 'POST',
          url: `${import.meta.env.VITE_ENGAGE_API_PUBLIC}/v2/update`,
          headers: {
            'Content-Type': 'application/json',
            origin: window.location.origin,
            'x-api-key': import.meta.env.VITE_ENGAGE_API_KEY,
          },
          data: JSON.stringify({
            email: user.email,
            workspace_slug: import.meta.env.VITE_ENGAGE_WORKSPACE_SLUG,
            expo_token: parsedPushTokens.expo.token,
            android_token:
              parsedPushTokens.native.platform === 'android'
                ? parsedPushTokens.native.token
                : 'undefined',
            ios_token:
              parsedPushTokens.native.platform === 'ios'
                ? parsedPushTokens.native.token
                : 'undefined',
          }),
        });
      }
    } else if (isUserFromWebView()) {
      const dataString = JSON.stringify(data);
      Cookies.set(CookieMap.accessToken, dataString);
      sendTokenToNativeApp(dataString);
    }

    callbackFunction();
  }

  async function signOut(isLogOut?: boolean) {
    if (isLogOut) {
      await apiAuthLogout.send();
      removeCookie('cached_sign_in');
    }

    if (window.isNativeApp) {
      const pushTokens = Cookies.get('pushNotificationTokens');
      if (pushTokens && user?.email) {
        axios.request({
          method: 'POST',
          url: `${import.meta.env.VITE_ENGAGE_API_PUBLIC}/v2/update`,
          headers: {
            'Content-Type': 'application/json',
            origin: window.location.origin,
            'x-api-key': import.meta.env.VITE_ENGAGE_API_KEY,
          },
          data: JSON.stringify({
            email: user.email,
            workspace_slug: import.meta.env.VITE_ENGAGE_WORKSPACE_SLUG,
            expo_token: 'user logged out',
            android_token: 'user logged out',
            ios_token: 'user logged out',
          }),
        });
      }
    }

    await handleSignOut();
  }

  async function updateUser(updateUser: User) {
    const safeNewUser = { ...user, ...updateUser };
    setUser(safeNewUser);
    await safestorage.setItem(StorageMap.User, safeNewUser);
  }

  async function getUser() {
    try {
      const userFromStorage = await safestorage.getItem<User>(StorageMap.User);

      // This force get user from api, to update the user experience.
      // Happens once per session. We can change this behavior in the future to update on refreshToken
      const alreadyRefresh = getFromStorage<boolean>(StorageMap.UserExperience);
      if (userFromStorage && alreadyRefresh) {
        if (userFromStorage.experience !== user?.experience) {
          updateUser(userFromStorage);
        }

        return userFromStorage;
      }

      const { data } = await apiAuthCustomer.send();
      if (data) {
        const score = await handleNegativeConsumer();

        let experience = data.experience;
        if (!score?.positiveCustomer && experience === 'score') {
          experience = 'offer';
        }

        const formattedUser: User = {
          ...data,
          positiveCustomer: score?.positiveCustomer,
          experience,
        };

        updateUser(formattedUser);
        setOnStorage('session', StorageMap.UserExperience, true);

        return formattedUser;
      }
    } catch (error) {
      handleErrorLog(error);
      await signOut();
    }
  }

  async function handleNegativeConsumer(): Promise<UserScore | undefined> {
    try {
      return await getScore();
    } catch (error: unknown) {
      handleErrorLog(error);
      return {} as UserScore;
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        getUser,
        updateUser,
        signIn,
        signOut,
        isAuthenticated,
        isLoading,
				usedAuthenticationType
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export { UserProvider, UserContext };
