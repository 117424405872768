import Cookies from 'js-cookie';

import { parseJwt } from '@libs/utils/helpers/parseJwt';
import safestorage from '@libs/utils/helpers/getSafeStorage';

import { ApiAuthResponse, ApiSigninResponse } from '@admission/apis/auth/types';
import { alovaInstanceAuth } from '@admission/apis/auth/alovaInstanceOnboarding';

import { CookieMap } from '@common/constants/CookieMap';
import { StorageMap } from '@common/constants/StorageMap';
import { removeFromStorage } from '@common/utils/handleStorage';
import {
  isUserFromWebView,
  getAccessData,
  sendTokenToNativeApp,
  cleanTokenNativeApp,
} from '@common/utils/webviewComunication';
import { handleErrorLog } from '@common/utils/handleErrorLogs';

const event = new Event('userNotAuthorized');

export async function handleRefreshToken() {
  try {
    const { data } = await alovaInstanceAuth.Post<
      ApiAuthResponse<ApiSigninResponse>
    >('/v1/public/auth/refresh-token');

    if (!data) {
      await handleSignOut();
      return null;
    }

    await setToken(data);
    return data;
  } catch (error) {
    handleErrorLog(error);
    await handleSignOut();
    return null;
  }
}

export function tokenExpired(token?: string) {
  if (!token) return true;

  const { exp } = parseJwt(token);
  const currentDateInTimeStamp = Math.floor(Date.now() / 1000);

  return currentDateInTimeStamp > exp;
}

async function setToken(token: ApiSigninResponse) {
  await safestorage.setItem(StorageMap.Token, token);

  if (isUserFromWebView()) {
    const dataString = JSON.stringify(token);
    Cookies.set(CookieMap.accessToken, dataString);
    sendTokenToNativeApp(dataString);
  }
}

export async function getToken(): Promise<ApiSigninResponse | null> {
  let token = await safestorage.getItem<ApiSigninResponse>(StorageMap.Token);

  if (isUserFromWebView()) {
    const _tk = await getAccessData();
    if (_tk) token = _tk;
  }

  return token;
}

export async function handleSignOut() {
  indexedDB.deleteDatabase('newco');
  removeFromStorage();

  await Promise.all([
    safestorage.removeItem(StorageMap.User),
    safestorage.removeItem(StorageMap.Token),
  ]);

  dispatchEvent(event);

  if (isUserFromWebView() && !window.isNativeApp) cleanTokenNativeApp();
  /* if(window?.cpRedline?.endSession){
    window.cpRedline.endSession()
  } */ //todo
}

export function isValidToken(token: ApiSigninResponse) {
  if (!token?.access_token) return false;

  const isAccessTokenExpired = tokenExpired(token.access_token);
  const isRefreshTokenExpired = tokenExpired(token?.refresh_token);
  const isOldPatternToken = token.expires_in_seconds > 3600;

  if (isOldPatternToken) {
    if (isAccessTokenExpired) return false;
    return true;
  }

  if (isAccessTokenExpired) {
    if (isRefreshTokenExpired) return false;
    return true;
  }

  return true;
}

export function shouldRefreshToken(token: ApiSigninResponse) {
  const isAccessTokenExpired = tokenExpired(token.access_token);
  const isOldPatternToken = token.expires_in_seconds > 3600;

  if (isAccessTokenExpired || isOldPatternToken) return true;
  return false;
}
