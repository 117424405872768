import { Text } from 'iq-blueberry';
import classNames from 'classnames';

import { buildAcronym } from '@libs/utils/helpers/buildAcronym';
import './styles.scss';

export interface IAvatarProps {
  username: string;
  className?: string;
}

export const Avatar: React.FC<IAvatarProps> = ({ username, className }) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const divClass = classNames('avatar', { [className!]: !!className });

  return (
    <div className={divClass}>
      <Text as="span" className="avatar__acronym">
        {buildAcronym(username)}
      </Text>
    </div>
  );
};
