/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation } from 'react-router-dom';
import { getAnonymousID } from 'redline-client-sdk';

interface ITrackingEventPayload {
  eventName: string;
  payload?: any;
}

export const useGoogleAnalytics = () => {
  const location = useLocation();

  function trackGA({ eventName, payload }: ITrackingEventPayload): void {
    if (window.isNativeApp && window?.NativeFeatures?.FirebaseAnalytics) {
      const event = {
        action: 'sendAnalyticsEvent',
        payload: { eventName, data: payload },
      };
      const stringfiedEvent = JSON.stringify(event);
      return window.ReactNativeWebView.postMessage(stringfiedEvent);
    }

    if (!window?.gtag) {
      return;
    }

    const anonymousId = getAnonymousID();
    const url = location.pathname;

    const safePayload = payload
      ? { ...payload, anonymousId, url }
      : { anonymousId, url };

    return window.gtag('event', eventName, safePayload);
  }

  return { trackGA };
};
