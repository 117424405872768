import { useState, useEffect } from 'react';

export function useStoryblokCache({
  getSH = true,
  getProduct = true,
}: {
  getSH?: boolean;
  getProduct?: boolean;
}) {
  const [sortingHat, setSortingHat] = useState<Record<string, any>>({});
  const [product, setProduct] = useState<Record<string, any>>({});
  const [sortingHatContent, setSortingHatContent] = useState<
    Record<string, any>[]
  >([]);
  const [productContent, setProductContent] = useState<Record<string, any>[]>(
    []
  );
  const [errorCount, setErrorCount] = useState(0);

  function getContent(context: any) {
    const stories = context?.stories;
    return stories?.map((storie: any) => storie.content ?? []);
  }

  async function fetchCacheSH() {
    try {
      const [productData, sortingHatData] = await Promise.all(
        [
          getProduct ? fetch(`./assets/storyblok/cache/productData.json?v=${Date.now()}`) : null,
          getSH ? fetch(`./assets/storyblok/cache/sortingHatData.json?v=${Date.now()}`) : null,
        ].filter((promise) => promise !== null)
      );

      if (sortingHatData && getSH) {
        const sortingHatValue = await sortingHatData.json();
        const sortingHatStories = getContent(sortingHatValue);
        setSortingHat(sortingHatValue);
        setSortingHatContent(sortingHatStories);
      }

      if (productData && getProduct) {
        const productValue = await productData.json();
        const productStories = getContent(productValue);

        setProduct(productValue);
        setProductContent(productStories);
      }

      if (getSH && sortingHatContent === null || getProduct && productContent === null) {
        setErrorCount((prevCount) => prevCount + 1)
      }
    } catch (error) {
      console.error('Error fetching data storyblok:', error);

      setErrorCount((prevCount) => prevCount + 1);
    }
  }

  useEffect(() => {
    if (errorCount < 10) fetchCacheSH();
  }, [errorCount]);

  return {
    product,
    sortingHat,
    productContent,
    sortingHatContent,
  };
}
