export enum ObjectiveSlug {
  //credit-card 3-items
  CardInDay = 'card-in-day',
  Punctual = 'punctual',
  CardPaid = 'card-paid',
  //consult 2-items
  LowProfile = 'low-profile',
  Security = 'security',
  //installments 2-items
  InstallmentBuyer = 'installment-buyer',
  InstallmentInDay = 'installment-in-day',
  //debts 1-item
  NoMoreDebts = 'no-more-debts',
  //generic 1-item
  GenericObjective = 'generic-objective',
}

export enum ScoreRange {
  Red = 'red',
  Orange = 'orange',
  Yellow = 'yellow',
  GreenLight = 'green-light',
  GreenDark = 'green-dark',
}
