import { Text } from 'iq-blueberry';
import giftIcon from '@common/assets/icon/gift.svg';

import './styles.scss';

interface NewFeatureTagProps {
  textTag: string;
}

export const NewFeatureTag: React.FC<NewFeatureTagProps> = ({ textTag }) => {
  return (
    <div className="new-feature-tag">
      <img
        className="new-feature-tag__img"
        src={giftIcon}
        alt="Caixa de presente"
      />
      <Text
        className="new-feature-tag__text"
        as="p"
        variant="body-small"
        variantDesk="body-medium"
      >
        {textTag}
      </Text>
    </div>
  );
};
