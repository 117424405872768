import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { delay } from '@libs/utils/helpers/delay';

import { useTracking } from '@common/hooks/useTracking';
import { handleErrorLog } from '@common/utils/handleErrorLogs';
import { SHDecisionItem, useSortingHat } from '@common/hooks/useSortingHat';

import { useHubRedirect } from '@hub/hooks/useHubRedirect';

export const useDetails = () => {
  const { shDecisionList, shLoading } = useSortingHat();
  const { pathname } = useLocation();
  const { elementClickedEvent } = useTracking();
  const { handleOfferRedirect, loadingRedirect, redirectModal } =
    useHubRedirect();

  const [error, setError] = useState(false);
  const [offer, setOffer] = useState<SHDecisionItem | null>(null);
  const [showContent, setShowContent] = useState(false);
  const [fixedButton, setFixedButton] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pathname && shDecisionList.length > 0) {
      const offer = getOfferFromUrl(pathname, shDecisionList);
      setOffer(offer);
      delay(100).then(() => setShowContent(true));
    }
  }, [pathname, shDecisionList]);

  useEffect(() => {
    const observer = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting) {
        setFixedButton(false);
      } else {
        setFixedButton(true);
      }
    });

    if (buttonRef.current) observer.observe(buttonRef.current);
    return () => {
      if (buttonRef.current) observer.unobserve(buttonRef.current);
    };
  }, [buttonRef, offer]);

  function getOfferFromUrl(path: string, shDecisionList: SHDecisionItem[]) {
    try {
      const slug = path.split('/').pop();
      if (!slug) return null;

      const offer = shDecisionList.find(
        (item) => item.generalInfo.slug === slug
      );

      if (!offer) throw new Error('Offer not found');

      return offer;
    } catch (error) {
      handleErrorLog(error);
      setError(true);
      return null;
    }
  }

  async function handleGoBack() {
    elementClickedEvent({
      elementType: 'button',
      location: pathname,
      text: 'Voltar',
    });

    await delay(150);
    window.history.back();
  }

  const handleRedirect = useCallback(async () => {
    if (!offer) return;
    await handleOfferRedirect({ offer, position: 0, location: pathname });
  }, [offer]);

  return {
    error,
    offer,
    shLoading,
    buttonRef,
    fixedButton,
    showContent,
    handleGoBack,
    handleRedirect,
    redirectModal,
    loadingRedirect,
  };
};
