import { useCallback, useEffect, useState } from 'react';

import {
  useSH,
  SHDecision,
  SHContent,
  SHMetadata,
  SHDecisionGeneralInfo,
} from '@libs/sortinghat';
import { delay } from '@libs/utils/helpers/delay';

import { useTracking } from '@common/hooks/useTracking';
import { useUserContext } from '@common/contexts/UserContext';
import { handleErrorLog } from '@common/utils/handleErrorLogs';

export type SHDecisionItem = {
  generalInfo: SHDecisionGeneralInfo;
  metadata: Partial<SHMetadata>;
  content: SHContent;
};

type UseSortingHat = {
  immediate?: boolean;
  decideDelayInSeconds?: number;
};
export const useSortingHat = ({
  immediate,
  decideDelayInSeconds,
}: UseSortingHat = {}) => {
  const { callSHTrack, callSHDecision, decisionsList, trackDone } = useSH();
  const { user, getUser } = useUserContext();
  const {
    shDecisionEnrichedEvent,
    shDecisionOutcomeReceivedEvent,
    shDecisionRequestedEvent,
  } = useTracking();

  const [shDecisionList, setShDecisionList] = useState<SHDecisionItem[]>([]);

  const [shLoading, setShLoading] = useState(true);
  const [shError, setShError] = useState(false);

  useEffect(() => {
    if (!user) getUser();
  }, []);

  useEffect(() => {
    if (immediate) {
      trackDone ? shDecision() : shTrack();
    }
  }, [trackDone, immediate, user]);

  useEffect(() => {
    formatDecisionList(decisionsList);
  }, [decisionsList]);

  const shTrack = useCallback(() => {
    try {
      if (user) {
        callSHTrack({ user, decisionEnrichedEvent: shDecisionEnrichedEvent });
      } else {
        console.warn('[SH_TRACK] User not found');
      }
    } catch (error) {
      handleErrorLog(error);
    }
  }, [user]);

  const shDecision = useCallback(async () => {
    try {
      if (trackDone) {
        if (decideDelayInSeconds) {
          // this logic is necessary because, decide default delay is 1 second
          const milliseconds = (decideDelayInSeconds - 1) * 1000;
          if (milliseconds > 0) {
            await delay(milliseconds);
          }
        }

        callSHDecision({
          user,
          decisionRequestEvent: shDecisionRequestedEvent,
          decisionOutcomeReceivedEvent: shDecisionOutcomeReceivedEvent,
        });
      } else {
        console.warn('[SH_DECIDE] Track not done yet');
      }
    } catch (error) {
      handleErrorLog(error);
    }
  }, [user, trackDone, decideDelayInSeconds]);

  function formatDecisionList(decisions: SHDecision[]) {
    try {
      if (decisions?.length > 0) {
        const formattedDecisions: SHDecisionItem[] = [];

        for (const decision of decisions) {
          if (!decision?.metadata?.content?.title) continue;

          const metadata: Partial<SHMetadata> = { ...decision.metadata };
          const content: SHContent = { ...decision.metadata.content };
          delete metadata.content;

          const formattedDecision: SHDecisionItem = {
            generalInfo: {
              category: decision?.category,
              id: decision?.id,
              partner: decision?.partner,
              slug: decision?.slug,
              featured: decision?.featured,
              limit: decision?.limit,
              preApproved: decision?.preApproved,
              modal: decision?.modal,
            },
            metadata,
            content,
          };

          formattedDecisions.push(formattedDecision);
        }

        setShDecisionList(formattedDecisions);
        setShError(false);
        setShLoading(false);
      }
    } catch (error) {
      handleErrorLog(error);
      setShError(true);
      setShLoading(false);
    }
  }

  return {
    shTrack,
    shDecision,
    shDecisionList,
    shError,
    shLoading,
  };
};
