import { StorageMap } from '@common/constants/StorageMap';
import { getFromStorage, setOnStorage } from './handleStorage';

export function addMinutesToCurrentTimestamp(cacheInMinutes: number) {
  const currentTime = new Date();
  const futureTime = new Date(currentTime.getTime() + cacheInMinutes * 60000);

  return futureTime.getTime();
}

export function isTimestampExpire(timestampToCompare: number) {
  const currentDate = new Date();
  return currentDate.getTime() > timestampToCompare;
}

export function setOnCache<T>({ data, expireInMinutes, key }: SaveOnCache<T>) {
  const cacheData: AppCache<T> = {
    expireIn: addMinutesToCurrentTimestamp(expireInMinutes),
    data: data,
  };

  setOnStorage('session', key, cacheData);
}

export function getFromCache<T>(key: StorageMap): T | null {
  const cacheScore = getFromStorage<AppCache<T>>(key);
  const hasCache = cacheScore && !isTimestampExpire(cacheScore.expireIn);
  if (!hasCache) return null;

  return cacheScore.data;
}

type AppCache<T> = {
  data: T;
  expireIn: number;
};

type SaveOnCache<T> = {
  key: StorageMap;
  data: T;
  expireInMinutes: number;
};
