import { IScoreRange, ScoreRange } from '@tally/types';

export const scoreRanges: IScoreRange = {
  [ScoreRange.Red]: {
    slug: ScoreRange.Red,
    label: 'vermelha',
    startRange: 0,
    endRange: 349,
    color: '#FF4C4C',
    secondaryColor: '#3E0303',
    previousRange: null,
    nextRange: ScoreRange.Orange,
    percentComplete: 0,
  },
  [ScoreRange.Orange]: {
    slug: ScoreRange.Orange,
    label: 'laranja',
    startRange: 350,
    endRange: 549,
    color: '#FFA64D',
    secondaryColor: '#612900',
    previousRange: ScoreRange.Red,
    nextRange: ScoreRange.Yellow,
    percentComplete: 0,
  },
  [ScoreRange.Yellow]: {
    slug: ScoreRange.Yellow,
    label: 'amarela',
    startRange: 550,
    endRange: 699,
    color: '#F2EA30',
    secondaryColor: '#614100',
    previousRange: ScoreRange.Orange,
    nextRange: ScoreRange.GreenLight,
    percentComplete: 0,
  },
  [ScoreRange.GreenLight]: {
    slug: ScoreRange.GreenLight,
    label: 'verde claro',
    startRange: 700,
    endRange: 849,
    color: '#A2DC28',
    secondaryColor: '#00421D',
    previousRange: ScoreRange.Yellow,
    nextRange: ScoreRange.GreenDark,
    percentComplete: 0,
  },
  [ScoreRange.GreenDark]: {
    slug: ScoreRange.GreenDark,
    label: 'verde escuro',
    startRange: 850,
    endRange: 1000,
    color: '#00812A',
    secondaryColor: '#fff',
    previousRange: ScoreRange.GreenLight,
    nextRange: null,
    percentComplete: 0,
  },
};
