import { Text } from 'iq-blueberry';
import { SHHighlight } from '@libs/sortinghat';

import './styles.scss';
import { useHubHighlights } from './hook';

export type HighlightsProps = {
  topics: SHHighlight[];
  className?: string;
  hideFeaturedTopics?: boolean;
};

export const HubHighlights = (props: HighlightsProps) => {
  const { topicList } = useHubHighlights(props);
  return (
    <div>
      {topicList.map((topic, index) => (
        <div className={topic.class} key={index}>
          <Text as="h5" variant="body-small">
            {topic.title}
          </Text>
          <Text as="span" variant="body-medium">
            <strong>{topic.description}</strong>
          </Text>
        </div>
      ))}
    </div>
  );
};
