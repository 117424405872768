import { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { RouteMap } from '@common/constants/RouteMap';
import { OfferDetails } from '@hub/pages/OfferDetails';
import { Details } from '@hub/pages/Details';
import { MyFavorites } from '@hub/pages/MyFavorites';

const Offers = lazy(() => import('@hub/pages/Offers'));
const MatchMaker = lazy(() => import('@hub/pages/MatchMaker'));
const SuccessFormFlow = lazy(
  () => import('@hub/pages/MatchMaker/SuccessFormFlow')
);

export function Router() {
  return (
    <>
      <Route path={RouteMap.Offer} element={<Offers />} />
      <Route path={RouteMap.OfferDetails} element={<OfferDetails />} />
      <Route path={RouteMap.ProductDetail} element={<Details />} />
      <Route path={RouteMap.MatchMaker} element={<MatchMaker />} />
      <Route path={RouteMap.MatchMakerResult} element={<SuccessFormFlow />} />
      <Route
        path={RouteMap.MyFavorites}
        element={
          window.isNativeApp && window.NativeFeatures?.NotificationSchedule ? (
            <MyFavorites />
          ) : (
            <Navigate to={RouteMap.Dashboard} />
          )
        }
      />
    </>
  );
}
