import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useRedline } from '@libs/redline';

import { RouteMap } from '@common/constants/RouteMap';
import constantsRedline from '@common/constants/redline';
import { LoaderCircular } from '@common/components/LoaderCircular';
import { useUserContext } from '@common/contexts/UserContext';

const PublicRoute = () => {
  const { isAuthenticated, isLoading } = useUserContext();
  const { pathname, search } = useLocation();
  const{ createCorrelationId } = useRedline();

	const searchParams = new URLSearchParams(search);
  const engageSigninData = searchParams.get("e");

  useEffect(() => {
    createCorrelationId(constantsRedline.RL_TRACK_CID);
  }, []);

  const isHelpCenter = pathname === RouteMap.Help;

  if (isLoading) return <LoaderCircular fullPage />;
  if (!isLoading && isAuthenticated && !isHelpCenter)
    return <Navigate to={RouteMap.Dashboard} replace />;

  return <Outlet />;
};

export default PublicRoute;
