import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { RouteMap } from '@common/constants/RouteMap';

//Public Routes
const Validation = lazy(() => import('@admission/pages/Validation'));
const SignIn = lazy(() => import('@admission/pages/SignIn'));
const SignUp = lazy(() => import('@admission/pages/SignUp'));
const ForgotPassword = lazy(() => import('@admission/pages/ForgotPassword'));
const RecoverPassword = lazy(() => import('@admission/pages/RecoverPassword'));

const Pin = lazy(() => import('@admission/pages/Pin'));
const Otp = lazy(() => import('@admission/pages/Otp'));

//Private Routes
const ChangePassword = lazy(
  () => import('../../admission/pages/ChangePassword')
);
const UserProfile = lazy(() => import('../../admission/pages/UserProfile'));
const ChangeEmail = lazy(() => import('../../admission/pages/ChangeEmail'));

export function useAdmissionRouter() {
  const admissionPublicPages = (
    <>
      <Route index element={<Validation />} />
      <Route path={RouteMap.SignIn} element={<SignIn />} />
      <Route path={RouteMap.SignUp} element={<SignUp />} />
      <Route path={RouteMap.ForgotPassword} element={<ForgotPassword />} />
      <Route path={RouteMap.RecoverPassword} element={<RecoverPassword />} />
      <Route path={RouteMap.Pin} element={<Pin />} />
      <Route path={RouteMap.Otp} element={<Otp />} />
    </>
  );

  const admissionPrivatePages = (
    <>
      <Route path={RouteMap.ChangePassword} element={<ChangePassword />} />
      <Route path={RouteMap.UserProfile} element={<UserProfile />} />
      <Route path={RouteMap.ChangeEmail} element={<ChangeEmail />} />
    </>
  );

  return {
    admissionPublicPages,
    admissionPrivatePages,
  };
}
