import { StorageMap } from '@common/constants/StorageMap';
import { useUserContext } from '@common/contexts/UserContext';
import { handleErrorLog } from '@common/utils/handleErrorLogs';
import { getFromCache, setOnCache } from '@common/utils/handleCache';

import { scoreRanges } from '@tally/constants/scoreRanges';
import { useApiScore } from '@tally/apis/score/useApiScore';
import {
  IScoreResponse,
  ScoreDetail,
  ScoreRange,
  UserScore,
} from '@tally/types';

export const useScore = () => {
  const { user, updateUser } = useUserContext();

  const { scoreRequest } = useApiScore();
  const sessionCache = import.meta.env.VITE_TALLY_SESSION_CACHE;

  async function getScore() {
    try {
      if (sessionCache) {
        const cacheData = getFromCache<UserScore>(StorageMap.Score);
        if (cacheData) return cacheData;
      }

      const { data } = await scoreRequest.send();
      if (!data?.score) throw new Error('no_score');
      if (user && user?.positiveCustomer === undefined) {
        updateUser({ ...user, positiveCustomer: data?.positiveCustomer });
      }

      const userScore = formatScore(data);
      if (sessionCache) {
        setOnCache({
          key: StorageMap.Score,
          data: userScore,
          expireInMinutes: 30,
        });
      }
      return userScore;
    } catch (error) {
      handleErrorLog(error);
    }
  }

  function formatScore(scoreResponse: IScoreResponse): UserScore {
    const { score, lastUpdate, positiveCustomer } = scoreResponse;
    const scoreRange = getScoreRange(score);
    if (!scoreRange) throw new Error('no_score_range');

    return {
      lastUpdate,
      value: score,
      detail: scoreRange,
      positiveCustomer,
    };
  }

  function getScoreRange(scoreValue: number): ScoreDetail {
    let selectedScore = {} as ScoreDetail;
    Object.entries(scoreRanges).forEach(([_, value]) => {
      if (scoreValue >= value.startRange && scoreValue <= value.endRange)
        selectedScore = value;
    });

    const rangePercentComplete = getRangePercent(selectedScore, scoreValue);
    selectedScore.percentComplete = rangePercentComplete;

    return selectedScore;
  }

  function getRangePercent(scoreDetail: ScoreDetail, scoreValue: number) {
    try {
      const { previousRange } = scoreDetail;
      const isFirstStep = previousRange === null;

      if (isFirstStep) {
        return Math.floor((scoreValue / scoreDetail.endRange) * 100);
      }

      const prevRange = scoreRanges[previousRange as ScoreRange];
      const proportionalScore = scoreValue - prevRange.endRange;
      const proportionalEndRange = scoreDetail.endRange - prevRange.endRange;

      return Math.floor((proportionalScore / proportionalEndRange) * 100);
    } catch (error: any) {
      const formattedError = {
        local: 'GET RANGE PERCENT',
        scoreValue,
        scoreDetail,
        ...error,
      };

      handleErrorLog(formattedError);
      throw error;
    }
  }

  return { getScore };
};
