import { PrivateDefaultTemplate } from '@common/Templates/PrivateDefault';
import './styles.scss';
import { Container } from '@common/components/Container';
import { Button, Conditional, Text } from '@consumidor-positivo/aurora';
import { FavoriteOffer } from './components/FavoriteOffer';
import { useMyFavorites } from './hook';
import { Spinetic, SpineticItem } from 'react-spinetic';
import { carouselConfig } from './carouselConfig';
import isMobile from '@libs/utils/helpers/isMobile';
import { FavoriteEmpty } from './components/FavoriteEmpty';

export const MyFavorites = () => {
  const { offers, handleRemove, ConfirmDialog } = useMyFavorites();

  const CustomDots = ({ currentIndex, remainingIndexes }: any) => {
    const textDots = isMobile() ? 'Cartão' : 'Página';
    return (
      <div>
        <Text as="p" variant="body-medium">
          {textDots}{' '}
          <strong>
            {currentIndex + 1} de {remainingIndexes?.length}
          </strong>
        </Text>
      </div>
    );
  };
  return (
    <PrivateDefaultTemplate>
      <Container>
        <div className="my-favorites">
          <div className="my-favorites__title">
            <Text
              as="h1"
              variant="heading-medium"
              variantDesk="heading-big"
              weight="bold"
            >
              Meus favoritos
            </Text>
          </div>
          <div className="my-favorites__credit">
            <div className="my-favorites__credit__texts">
              <Text
                as="h2"
                variant="heading-small"
                variantDesk="heading-large"
                weight="bold"
              >
                Cartões de crédito
              </Text>
            </div>
            <div className="my-favorites__credit__content">
              <Conditional
                condition={offers?.length > 0}
                renderIf={
                  <Spinetic
                    config={carouselConfig}
                    CustomChildrenDots={CustomDots}
                  >
                    {offers?.map((offer, index) => (
                      <SpineticItem key={'credit' + index}>
                        <FavoriteOffer
                          offer={offer}
                          position={index}
                          handleRemove={() => handleRemove(offer)}
                        />
                      </SpineticItem>
                    ))}
                  </Spinetic>
                }
                renderElse={<FavoriteEmpty />}
              />
            </div>
          </div>
          <div className="my-favorites__button">
            <Button
              expand="x"
              type="outlined"
              onClick={() => window.history.back()}
            >
              Voltar
            </Button>
          </div>
        </div>
        <ConfirmDialog />
      </Container>
    </PrivateDefaultTemplate>
  );
};
