import { useEffect } from 'react';

type RedirectProps = {
  to: string;
};

export const Redirect = ({ to }: RedirectProps) => {
  useEffect(() => window.location.replace(to), []);

  return <></>;
};
