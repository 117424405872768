import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RedlineProvider } from '@libs/redline';
import { UserProvider } from '@common/contexts/UserContext/context';
import { DogxProvider } from '@libs/dogx';
import { SHProvider } from '@libs/sortinghat';

import { WebviewBridge } from '@common/components/WebviewBridge';
import App from './App';
import { handleEnvVar } from '@common/utils/handleEnvVar';
import getRedlineAppName from '@common/utils/getRedlineAppName';
import { createRedline } from 'redline-client-sdk';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const rl = createRedline({
  writeKey: handleEnvVar('VITE_REDLINE_WRITE_KEY'),
  mode: import.meta.env.VITE_ENV,
  logs: false,
  appName: getRedlineAppName(),
  appVersion: import.meta.env.VITE_DEPLOY_VERSION,
  autoTracking: {
    documentHide: false
  }
});

const redlineAppName = getRedlineAppName();

root.render(
  <RedlineProvider instance={rl} appName={redlineAppName}>
    <DogxProvider>
      <UserProvider>
        <SHProvider
          appName="cp"
          mode={import.meta.env.VITE_ENV}
          shKey={import.meta.env.VITE_SORTING_HAT_API_KEY}
          baseURL={import.meta.env.VITE_SORTING_HAT_API_URL}
        >
          <BrowserRouter>
            <WebviewBridge>
              <App />
            </WebviewBridge>
          </BrowserRouter>
        </SHProvider>
      </UserProvider>
    </DogxProvider>
  </RedlineProvider>
);
