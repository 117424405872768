import { useRequest } from "alova";

import { alovaInstanceAuth } from "@admission/apis/auth/alovaInstanceOnboarding";
import { ApiAuthResponse, ApiSigninResponse } from "@admission/apis/auth/types";

const useApiLoginEnrichment = () => {
  const apiLoginEnrichment = useRequest(
    () => alovaInstanceAuth.Patch<ApiAuthResponse<ApiSigninResponse>>(
        '/v1/app/login-enrichment',
      ),
    {
      immediate: false,
    }
  );

  return {
    apiLoginEnrichment
  }
};

export default useApiLoginEnrichment;
