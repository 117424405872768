import cpBadge from '@common/assets/icon/menu/badge-cp.svg';
import cpChartRetangle from '@common/assets/icon/menu/chart-retangle-cp.svg';
import cpChartBar from '@common/assets/icon/menu/chart-bar-cp.svg';
import cpHandCard from '@common/assets/icon/menu/hand-card-cp.svg';
import cpFilledBill from '@common/assets/icon/menu/filled-bill-fold-cp.svg';
import cpCardStar from '@common/assets/icon/menu/card-start.svg';
import cpPadlock from '@common/assets/icon/menu/padlock-cp.svg';
import cpLifebouy from '@common/assets/icon/menu/lifebuoy-cp.svg';
import cpLinkIcon from '@common/assets/icon/chevron-right-cp.svg';
import cpFileChecklist from '@common/assets/icon/cp/file-checklist.svg';
import filledBillMoney from '@common/assets/icon/cp/filled-bill-money.svg';
import cpBillBarCode from '@common/assets/icon/cp/bill-bar-code.svg';
import cpHeart from '@common/assets/icon/menu/heart-cp.svg';
import { RouteMap } from '@common/constants/RouteMap';

export interface item {
  label: string;
  icon: string;
  slug: RouteMap;
  title: string;
}

export const useMenuItems = (): Array<item> => {
  const itensMenu: Array<item> = [
    {
      label: 'Aumentar meu Score',
      icon: cpBadge,
      slug: RouteMap.Objectives,
      title: 'Aumentar meu Score',
    },
    {
      label: 'Histórico de Score',
      icon: cpChartRetangle,
      slug: RouteMap.ObjectivesHistoric,
      title: 'Histórico de Score',
    },
    {
      label: 'Ver meu progresso',
      icon: cpChartBar,
      slug: RouteMap.ObjectivesProgress,
      title: 'Ver meu progresso',
    },
    {
      label: 'Match Positivo',
      icon: cpCardStar,
      slug: RouteMap.MatchMaker,
      title: 'Match Positivo',
    },
    {
      label: 'Minhas ofertas',
      icon: cpHandCard,
      slug: RouteMap.Offer,
      title: 'Ofertas',
    },
    ...(window?.isNativeApp && window?.NativeFeatures?.NotificationSchedule
      ? [
          {
            label: 'Meus Favoritos',
            icon: cpHeart,
            slug: RouteMap.MyFavorites,
            title: 'Meus Favoritos',
          },
        ]
      : []),

    {
      label: 'Dívidas para negociar',
      icon: cpBillBarCode,
      slug: RouteMap.MyDebts,
      title: 'Dívidas para negociar',
    },
    {
      label: 'Dívidas negativadas',
      icon: cpFilledBill,
      slug: RouteMap.NegativeDebts,
      title: 'Dívidas negativadas',
    },
    {
      label: 'Contas e Pagamentos',
      icon: filledBillMoney,
      slug: RouteMap.BillsAndPayments,
      title: 'Dívidas negativadas',
    },
    {
      label: 'Consultas em meu CPF',
      icon: cpFileChecklist,
      slug: RouteMap.CpfLookout,
      title: 'Consultas em meu CPF',
    },
    {
      label: 'Alterar senha',
      icon: cpPadlock,
      slug: RouteMap.ChangePassword,
      title: 'Alterar Senha',
    },
    {
      label: 'Central de ajuda',
      icon: cpLifebouy,
      slug: RouteMap.Help,
      title: 'Ajuda',
    },
  ];

  return itensMenu;
};

export const rightIcon = cpLinkIcon;
