interface TimeIntervalTriggerInput {
  channelId?: string;
  repeats?: boolean;
  seconds: number;
}

interface DailyTriggerInput {
  channelId?: string;
  hour: number;
  minute: number;
  repeats: true;
}

interface WeeklyTriggerInput {
  channelId?: string;
  weekday: number;
  hour: number;
  minute: number;
  repeats: true;
}

interface YearlyTriggerInput {
  channelId?: string;
  day: number;
  month: number;
  hour: number;
  minute: number;
  repeats: true;
}

type DateTriggerInput =
  | Date
  | number
  | { channelId?: string; date: Date | number };

type SchedulableNotificationTriggerInput =
  | DateTriggerInput
  | TimeIntervalTriggerInput
  | DailyTriggerInput
  | WeeklyTriggerInput
  | YearlyTriggerInput;

interface ScheduleNotification {
  identifier?: string;
  content: {
    title?: string;
    body?: string;
    subtitle?: string;
    data?: { target_url: string };
  };
  trigger: SchedulableNotificationTriggerInput;
}

export const scheduleNotification = ({
  identifier,
  content,
  trigger,
}: ScheduleNotification) => {
  const message = {
    action: 'scheduleNotification',
    payload: {
      identifier,
      content,
      trigger,
    },
  };

  if (window.isNativeApp && window.NativeFeatures?.NotificationSchedule) {
    const stringifiedMessage = JSON.stringify(message);
    window.ReactNativeWebView.postMessage(stringifiedMessage);
  }
};

export const removeScheduleNotification = (identifier: string) => {
  const message = {
    action: 'removeScheduleNotification',
    payload: {
      identifier,
    },
  };

  if (window.isNativeApp) {
    const stringifiedMessage = JSON.stringify(message);
    window.ReactNativeWebView.postMessage(stringifiedMessage);
  }
};
