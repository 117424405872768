/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';

type HandleErrorLogOptions = {
  notLogStatus?: number[];
  onlyLogStatus?: number[];
  notLogSlug?: string[];
  onlyLogSlug?: string[];
  notLogMessage?: string[];
  onlyLogMessage?: string[];
};
export function handleErrorLog(
  error: Error | AxiosError | CustomApiError | unknown,
  options?: HandleErrorLogOptions
) {
  const showLog = mustShowLog(error, options);
  if (error instanceof AxiosError) {
    const status = error?.response?.status || '000';
    const method = error.config?.method?.toUpperCase() || 'NO METHOD';
    const apiUrl = error.config?.url || 'NO URL';
    const errorMessage = getErrorMessage(error);

    if (showLog) {
      console.error(`[${status}]: ${method} - ${apiUrl} | ${errorMessage}`);
    }
    throw new CustomApiError(error);
  }

  if (showLog) {
    console.error('[JS]:', error);
  }
}

function getErrorMessage(error: AxiosError<any>) {
  return (
    error.response?.data?.message ||
    handleErrorMessage(error.response?.data?.data) ||
    error?.message ||
    'error without message'
  );
}

function handleErrorMessage(errorData?: string | object) {
  if (typeof errorData === 'string') return errorData;
  if (typeof errorData === 'object') return JSON.stringify(errorData);

  return undefined;
}

function mustShowLog(
  error: Error | AxiosError | CustomApiError | unknown,
  options?: HandleErrorLogOptions
) {
  if (error instanceof AxiosError) {
    const status = error?.response?.status || 0;
    const slug = error?.response?.data?.error_slug;
    const message = error?.response?.data?.message;

    if (options?.notLogStatus) return !options.notLogStatus.includes(status);
    if (options?.onlyLogStatus) return options.onlyLogStatus.includes(status);

    if (options?.notLogSlug) return !options.notLogSlug.includes(slug);
    if (options?.onlyLogSlug) return options.onlyLogSlug.includes(slug);

    if (options?.notLogMessage) return !options.notLogMessage.includes(message);
    if (options?.onlyLogMessage)
      return options.onlyLogMessage.includes(message);

    return true;
  }

  if (!(error instanceof CustomApiError) || !error.apiError) {
    const message = (error as any)?.message;
    if (options?.notLogMessage) return !options.notLogMessage.includes(message);
    if (options?.onlyLogMessage)
      return options.onlyLogMessage.includes(message);

    return true;
  }

  return false;
}

export class CustomApiError extends Error {
  apiError: boolean;

  constructor(error?: Error | AxiosError) {
    super(error?.message);
    const newError = error instanceof AxiosError ? error.response?.data : error;
    Object.assign(this, { ...newError });

    this.apiError = true;

    if (error) this.stack = error.stack;
  }
}
