import './styles.scss';
import { ReactNode, useEffect } from 'react';
import cpClose from '@common/assets/icon/close-cp.svg';
import { useDeviceSize } from '@libs/utils/hooks/useDeviceSize';
import { Conditional } from 'iq-blueberry';
import classnames from 'classnames';
import Confetti from 'react-confetti';

export interface ModalProps {
  children?: ReactNode;
  className?: string;
  title?: string;
  showModal: boolean;
  mobile: 'bottom' | 'center';
  desktop: 'side' | 'center';
  showConfetti?: boolean;
  hasCloseButton?: boolean;
  onClose?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  className,
  title,
  showModal,
  mobile,
  desktop,
  hasCloseButton = true,
  showConfetti = false,
  onClose,
}) => {
  const { isMobile } = useDeviceSize();

  function close(element: Element) {
    if (element.id === 'modal') onClose && onClose();
  }

  useEffect(() => {
    document.body.style.overflow = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  return (
    <Conditional
      condition={isMobile}
      renderIf={
        <div
          className={classnames(
            'modal',
            {
              'modal-mobile-center': mobile === 'center',
              'modal-mobile-bottom': mobile === 'bottom',
              'modal-open': showModal,
              'modal-close': showModal === false,
            },
            className
          )}
          id="modal"
          onClick={(e) => close(e.target as Element)}
        >
          <Conditional
            condition={showConfetti && showModal}
            renderIf={
              <Confetti
                opacity={0.8}
                colors={['#94F0CF', '#0048DB', '#026441', '#9ABCFF']}
              />
            }
          />
          <div
            className={classnames('modal__card', {
              'card-mobile-center': mobile === 'center',
              'card-mobile-bottom': mobile === 'bottom',
              'slide-in-bottom': showModal,
              'slide-out-bottom': !showModal,
            })}
            id="card"
          >
            <Conditional
              condition={hasCloseButton}
              renderIf={
                <div className="modal__close">
                  <img
                    src={cpClose}
                    alt="Icone de fechar"
                    onClick={onClose}
                  ></img>
                </div>
              }
            />

            {title && <h1 className="modal__title">{title}</h1>}
            <div className="modal__content">{children}</div>
          </div>
        </div>
      }
      renderElse={
        <div
          className={classnames(
            'modal',
            {
              'modal-desktop-center': desktop === 'center',
              'modal-desktop-side': desktop === 'side',
              'modal-open': showModal,
              'modal-close': !showModal,
            },
            className
          )}
          id="modal"
          onClick={(e) => close(e.target as Element)}
        >
          <Conditional
            condition={showConfetti && showModal}
            renderIf={
              <Confetti
                opacity={0.8}
                colors={['#94F0CF', '#0048DB', '#026441', '#9ABCFF']}
              />
            }
          />
          <div
            className={classnames('modal__card', {
              'card-desktop-center': desktop === 'center',
              'card-desktop-side': desktop === 'side',
              'slide-in-bottom': showModal && desktop === 'center',
              'slide-out-bottom': !showModal && desktop === 'center',
              'slide-in-right': showModal && desktop === 'side',
              'slide-out-right': !showModal && desktop === 'side',
            })}
            id="card"
          >
            <Conditional
              condition={hasCloseButton}
              renderIf={
                <div className="modal__close">
                  <img
                    src={cpClose}
                    alt="Icone de fechar"
                    onClick={onClose}
                  ></img>
                </div>
              }
            />

            {title && <h1 className="modal__title">{title}</h1>}
            <div className="modal__content">{children}</div>
          </div>
        </div>
      }
    />
  );
};
